<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Organizations" to="/organizations/list/1" />
      <q-breadcrumbs-el label="States" to="/organizations/state" />
      <q-breadcrumbs-el :label="siteArrays && siteArrays.states && siteArrays.states[$route.params.stateCode]" />
    </q-breadcrumbs>

  <div class="row justify-between items-end q-mb-sm q-mt-md">
    <div class="col full-height text-h5">
      Total {{ siteArrays && siteArrays.states && siteArrays.states[$route.params.stateCode]}} Organizations: {{numRecs}} <span v-if="numRecs != numTotalRecs">
        of {{numTotalRecs}}
        </span>
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <tr v-for="org in recs" :key="org._id">

    <td>
      <div class="text-body1 text-weight-medium">
        <router-link :to="{ name: 'orgView', params: { recId: org._id }}" class="text-primary">{{org.name}}</router-link>
      </div>
    </td>
    <td class="text-right text-sm">
      <div class="text-no-wrap">
      {{org.location.city}}<span v-if="org.location.state_code">,
      <router-link :to="{ name: 'orgStateList', params: { stateCode: org.location.state_code, pageId: 1 } }" class="text-primary">{{org.location.state_code}}</router-link></span>
      {{org.location.country_code}}
      </div>
    </td>

    </tr>
  </table>
</div>
</template>

<script>
import orgModule from "../../../store";
import storeRecListMixin from "src/mixins/store_rec_list.mixin";
import { mapState } from 'vuex';

export default {
  mixins: [
    storeRecListMixin,
  ],
  data() {
    return {
      collection: 'organizations',
      vuexCollection: 'organization.recs',
      vuexPath: 'organization',
      vuexPageRequestAction: orgModule.actionTypes.LoadRecordsPageRequest,

      qry: { 'location.state_code': this.$route.params.stateCode },
    }
  },
  computed: {
      // ...mapState('org', {
      //   loading: 'loading',
      //   numOrgs: 'qry_total',
      // }),
      ...mapState('site', {
        siteArrays: 'arrs',
      }),
      // ...mapGetters('org', [
      //   'hasPageSet',
      // ]),
      // currentPage() {
      //   return +this.$route.params.pageId;
      // },
      // numPages() {
      //   return Math.ceil(this.numOrgs / 25);
      // },
      // stateCode() {
      //   return this.$route.params.stateCode;
      // },
      // orgs() {
      //   return this.$store.getters['org/getPageSet'](this.currentPage);
      // },
  },

  watch: {
    // '$route': function() { this.reqData(); },
  },

  created () {
    // this.reqData();
  },

  methods: {
    bookmark (postId) {
      console.log('bookmark post', postId);
    },
     gotoPage (pageId) {
      this.$store.dispatch( 'organization/' + orgModule.actionTypes.LoadRecordsPageRequest, { page_id: +pageId,  qry: { 'location.state_code': this.$route.params.stateCode } } );

      this.$router.push({ params: { pageId }});
    },
    reqData (force) {
      if (! this.hasPageSet(this.$route.params.pageId, this.qry) ||  force ) {
        const payload = {
          page_id: +this.$route.params.pageId,
          qry: { 'location.state_code': this.$route.params.stateCode }
        };

        this.$store.dispatch( 'organization/' + orgModule.actionTypes.LoadRecordsPageRequest, payload);
      }
    },

  },
}
</script>



